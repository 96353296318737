import { SubscribeButtonProps } from '@/interfaces';
import React from 'react';
import { projectImages } from '../../../public/Assets/Images';
import TypoGraphy from '@/typography';
import './index.scss';
import Image from 'next/image';
import { gumletLoader } from '@/utils/gumlet';
const SubscribeButton = ({
  clickHandler
}: SubscribeButtonProps) => {
  return <div className="subscribe_button_wrraper" onClick={clickHandler} data-sentry-component="SubscribeButton" data-sentry-source-file="index.tsx">
      <div className="glare_div">
        <div className="glare_one"></div>
        <div className="glare_two"></div>
      </div>
      <div className="green_div">
        <TypoGraphy text='Subscribe' variant='Mobile/Body 1/M | 16 | 120' data-sentry-element="TypoGraphy" data-sentry-source-file="index.tsx" />
        <Image loader={gumletLoader} width={12} height={13} src={projectImages.marketingIconRight} alt="right" loading='lazy' data-sentry-element="Image" data-sentry-source-file="index.tsx" />
      </div>
    </div>;
};
export default SubscribeButton;