import { apiService } from '@/services/apiService';
import { LEARNERS_ENDPOINTS } from '@/services/endPoints';
import { useQuery } from '@tanstack/react-query';
export const getUserLoginKey = () => {
  return ['user-login'];
};
export const CheckLoginStatus = () => {
  const fetchData = async () => {
    return (await apiService(LEARNERS_ENDPOINTS.SERVER_URL + LEARNERS_ENDPOINTS.checkLogin, true, {
      credentials: 'include'
    })).json();
  };
  return useQuery<any>({
    queryKey: getUserLoginKey(),
    queryFn: fetchData,
    staleTime: Infinity
  });
};