"use client";

import { useState, lazy } from 'react';
import './index.scss';
import TypoGraphy from '@/typography';
import Button from '@/uicomponents/Button';
import { homePageImages, projectImages } from '../../../public/Assets/Images';
import useViewType from '@/utils/Hooks/useViewType';
import { growthItems, learnItems } from '@/utils/staticdata';
import { LearnItem } from '@/interfaces';
import Image from 'next/image';
import { gumletLoader } from '@/utils/gumlet';
const ModalDarkScreen = lazy(() => import('@/reusablecomponents/Modals/ModalDarkScreen'));
const AbsoluteModal = lazy(() => import('@/reusablecomponents/Modals/AbsoluteModal'));
const MobileModal = lazy(() => import('@/reusablecomponents/Modals/MobileModal'));
const Navbar = ({
  isHomePage
}: any) => {
  const [learnModal, setlearnModal] = useState(false);
  const isMobile = useViewType();
  const [hoverState, setHoverState] = useState(false);
  const [navbarModal, setnavbarModal] = useState(false);
  const [loginHover, setloginHover] = useState(false);
  const [applyHover, setapplyHover] = useState(false);
  return <>
      <div className={isHomePage ? "navbar_home" : "navbar_wrapper"}>
        <div className="navbar_left_content">
          <div className="navbar_main_wrapper">
            <div className="navbar_logo_wrapper">
              <img src={isHomePage ? homePageImages['GS Logo Fulllogonew'] : projectImages.gsLogo} alt="logo" onClick={() => window.location.href = 'https://growthschool.io/'} />
            </div>
            <div className="navbar_sub_wrapper">
              <div className="navbar_sub_button" onClick={() => setlearnModal(true)} onMouseEnter={() => setHoverState(true)} onMouseLeave={() => setHoverState(false)}>
                <Button variant='NavbarVariant' text='Learn' backgroundColor={hoverState || learnModal ? "white" : "transparent"} textVariant={'Desktop/Body 1/M | 16 |120'} textColor={isHomePage ? '#002726' : hoverState || learnModal ? "black" : '#FFF'} styles={{
                width: "100px",
                border: isHomePage ? "1px solid #002726" : ''
              }} imageHeight={12} imageWidth={9} image={isHomePage ? homePageImages['learngren'] : hoverState || learnModal ? projectImages.learnDownDark : projectImages.doubleArrowDownWhite} data-sentry-element="Button" data-sentry-source-file="index.tsx" />
              </div>
              <div className="navbar_links">
                <a href='https://home.growthschool.io/b2b' className="navbar_link_item">
                  <TypoGraphy variant='Desktop/Body 1/M | 16 |120' text='Upskill your Team' color={isHomePage ? '#002726' : '#FFF'} data-sentry-element="TypoGraphy" data-sentry-source-file="index.tsx" />
                </a>
                <a href='https://home.growthschool.io/about-us' className="navbar_link_item">
                  <TypoGraphy variant='Desktop/Body 1/M | 16 |120' text='About' color={isHomePage ? '#002726' : '#FFF'} data-sentry-element="TypoGraphy" data-sentry-source-file="index.tsx" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="navbar_right_content">
          <div className="navbar_buttons_wrapper">
            <div className="navbar_item_button" onMouseEnter={() => setloginHover(true)} onMouseLeave={() => setloginHover(false)} onClick={() => window.location.href = 'https://api.growthschool.io/login'}>
              <Button backgroundColor={loginHover ? isHomePage ? "#002726" : "white" : "transparent"} variant='NavbarVariant' text='Login' textVariant='Desktop/Body 1/M | 16 |120' textColor={isHomePage ? loginHover ? '#FFF' : '#002726' : loginHover ? "black" : '#FFF'} styles={isHomePage ? {
              border: '1px solid #002726'
            } : {}} data-sentry-element="Button" data-sentry-source-file="index.tsx" />
            </div>
            <div className="navbar_item_button" onMouseEnter={() => setapplyHover(true)} onMouseLeave={() => setapplyHover(false)} onClick={() => window.location.href = 'https://home.growthschool.io/apply-as-mentor'}>
              <Button backgroundColor={applyHover ? isHomePage ? "#002726" : "white" : "transparent"} variant='NavbarVariant' text='Apply as Mentor' textVariant='Desktop/Body 1/M | 16 |120' textColor={isHomePage ? applyHover ? '#FFF' : '#002726' : applyHover ? "black" : '#FFF'} styles={isHomePage ? {
              border: '1px solid #002726'
            } : {}} data-sentry-element="Button" data-sentry-source-file="index.tsx" />
            </div>
          </div>
        </div>
        <div className="mobile_right_content">
          <div className="navbar_sub_button" onClick={() => setlearnModal(true)}>
            <Button variant='NavbarVariant' text='Learn' textVariant='Desktop/Body 1/M | 16 |120' textColor='#FFF' data-sentry-element="Button" data-sentry-source-file="index.tsx" />
          </div>
          <Image loader={gumletLoader} width={48} height={44.2} src={projectImages.hamburger} alt="hamburger" onClick={() => setnavbarModal(true)} data-sentry-element="Image" data-sentry-source-file="index.tsx" />
        </div>
      </div>
      {learnModal && <ModalDarkScreen closeOnClick closehandler={() => setlearnModal(false)}>
        {isMobile ? <MobileModal closehandler={() => setlearnModal(false)}>
          <div className="modal_items_wrapper_mobile">
            {learnItems.map((item: LearnItem, index: number) => <a href={item.url} className={'learn_item'} key={`leran_item_${index}`}>
                <div className={'flex_item'}>
                  <TypoGraphy variant='Desktop/Body 1/M | 16 |120' text={item.name} />{<img src={projectImages.marketingIconRight} alt="right" />}
                </div>
              </a>)}
            <div className="divider_line"></div>
            {growthItems.map((item: LearnItem, index: number) => <a href={item?.url} className="learn_item" key={`growth_item_${index}`}>
                <div className={'flex_item'}>
                  <TypoGraphy variant='Desktop/Body 1/M | 16 |120' text={item.name} />{<img src={projectImages.marketingIconRight} alt="right" />}
                </div>
              </a>)}
          </div>
        </MobileModal> : <AbsoluteModal left={"200px"} top={"112px"}>
          <div className="modal_items_wrapper">
            {learnItems.map((item: LearnItem, index: number) => <a href={item.url} className='learn_item' key={`leran_item_${index}`}>
                <div className="flex_item">
                  <TypoGraphy variant='Desktop/Body 1/M | 16 |120' text={item.name} /><img src={projectImages.marketingIconRight} alt="right" />
                </div>
              </a>)}
            <svg xmlns="http://www.w3.org/2000/svg" width="232" height="2" viewBox="0 0 232 2" fill="none">
              <path opacity="0.3" d="M1 1H231" stroke="#686C7A" strokeWidth="2" strokeLinecap="round" />
            </svg>
            {growthItems.map((item: LearnItem, index: number) => <a href={item?.url} className="learn_item" key={`growth_item_${index}`}>
                <div className="flex_item">

                  <TypoGraphy variant='Desktop/Body 1/M | 16 |120' text={item.name} /><img src={projectImages.marketingIconRight} alt="right" />
                </div>
              </a>)}
          </div>
        </AbsoluteModal>}
      </ModalDarkScreen>}
      {navbarModal && <MobileModal closehandler={() => setnavbarModal(false)}>
          <div className="navbar_modal_wrapper">
            <div className="text_wrapper">
              <a href='https://home.growthschool.io/b2b' className="navbar_link_item">

                <TypoGraphy text='Upskill your team' variant='Mobile/Body 2/M | 14 | 120' />
              </a>
              <a href='https://home.growthschool.io/about-us' className="navbar_link_item">

                <TypoGraphy text='About' variant='Mobile/Body 2/M | 14 | 120' />
              </a>
            </div>
            <div className="divider_line"></div>
            <div className="buttons_wrapper">
              <div onClick={() => window.location.href = 'https://api.growthschool.io/login'}>
                <Button text='Login' textColor='black' backgroundColor='white' textVariant='Mobile/Body 2/M | 14 | 120' borderColor='rgba(3, 10, 33, 0.20)' variant='NormalVariant' />
              </div>
              <div onClick={() => window.location.href = 'https://home.growthschool.io/apply-as-mentor'}>
                <Button text='Apply as Mentor' textColor='black' backgroundColor='white' textVariant='Mobile/Body 2/M | 14 | 120' borderColor='rgba(3, 10, 33, 0.20)' variant='NormalVariant' />

              </div> </div>
          </div>
        </MobileModal>}
    </>;
};
export default Navbar;