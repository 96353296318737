"use client";

import { projectStrings } from '@/utils/projectstrings';
import TypoGraphy from '@/typography';
import { useContext, useEffect, useState } from 'react';
import './index.scss';
import { projectImages } from '../../../public/Assets/Images';
import NormalModal from '@/reusablecomponents/Modals/NormalModal';
import { usePathname } from 'next/navigation';
import useViewType from '@/utils/Hooks/useViewType';
import { TimeZone, timezones } from '@/utils/timezones';
import { AppContext } from '@/contexts/appcontext';
import MobileModal from '@/reusablecomponents/Modals/MobileModal';
import SubscribeButton from '@/reusablecomponents/SubscribeButton';
import Button from '@/uicomponents/Button';
import Image from 'next/image';
import moment from 'moment-timezone';
import { gumletLoader } from '@/utils/gumlet';
const footerData = [{
  name: "Industry",
  items: [{
    name: "Product",
    link: "https://home.growthschool.io/industry/product"
  }, {
    name: "Marketing",
    link: "https://home.growthschool.io/industry/marketing"
  }, {
    name: "Design",
    link: "https://home.growthschool.io/industry/design"
  }, {
    name: "Tech & Data",
    link: "https://home.growthschool.io/industry/tech-and-data"
  }, {
    name: "Generative AI",
    link: "https://home.growthschool.io/industry/generative-ai"
  }, {
    name: "Business",
    link: "https://home.growthschool.io/industry/business"
  }]
}, {
  name: "Roles",
  items: [{
    name: "Personal Growth",
    link: "https://home.growthschool.io/industry/personal-growth"
  }, {
    name: "Founders",
    link: "https://home.growthschool.io/industry/founders"
  }]
}, {
  name: "Engage",
  items: [{
    name: "GrowthSchool for Teams",
    link: "https://home.growthschool.io/b2b"
  }, {
    name: "Apply as Mentor",
    link: "https://home.growthschool.io/apply-as-mentor"
  }, {
    name: "Free courses",
    link: process.env.NEXT_PUBLIC_APP_URL + "/in/courses"
  }, {
    name: "Topics",
    link: process.env.NEXT_PUBLIC_APP_URL + "/in/topics"
  }]
}, {
  name: "Company",
  items: [{
    name: "About Us",
    link: "https://home.growthschool.io/about-us"
  }, {
    name: "Careers",
    link: "https://home.growthschool.io/careers"
  }, {
    name: "Contact Us",
    link: "https://home.growthschool.io/contact-us"
  }, {
    name: "Terms of Service",
    link: "https://home.growthschool.io/terms-and-conditions"
  }, {
    name: "Refund Policy",
    link: "https://home.growthschool.io/refund-policy"
  }, {
    name: "Privacy Policy",
    link: "https://home.growthschool.io/privacy-policy"
  }]
}];
const socialMediaImages = [projectImages.fbIconLight, projectImages.twitterIconLight, projectImages.instaIconLight, projectImages.linkedinIconLight];
const socialMediaIcons = [{
  platform: "Facebook",
  link: "https://www.facebook.com/growthschoolio",
  image: projectImages.fbIconLight
}, {
  platform: "Twitter",
  link: "https://twitter.com/growthschoolio",
  image: projectImages.twitterIconLight
}, {
  platform: "Instagram",
  link: "https://www.instagram.com/growthschoolio/",
  image: projectImages.instaIconLight
}, {
  platform: "LinkedIn",
  link: "https://www.linkedin.com/company/growthschoolio/mycompany/",
  image: projectImages.linkedinIconLight
}];
const SuggestedTimzones: Array<TimeZone> = timezones.filter((timezone: TimeZone) => timezone.offset > 4 && timezone.offset <= 7);
const timeZoneData = [{
  title: "Suggested Timezones",
  items: SuggestedTimzones
}, {
  title: "Other Timezones",
  items: timezones
}];
const Footer = ({
  isHomePage
}: any) => {
  const {
    setSelectedTimeZone
  } = useContext<any>(AppContext);
  const [showModal, setShowModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [timeZoneItem, setTimeZoneItem] = useState<TimeZone>(timezones.filter((item: any) => item.abbr === moment().tz(moment.tz.guess()).format('z'))[0]);
  const [footerInputText, setfooterInputText] = useState('');
  const [mobileModal, setmobileModal] = useState(false);
  const [searchText, setsearchText] = useState('');
  const [newsModal, setNewsModal] = useState(false);
  const [fullName, setFullName] = useState('');
  const [isOpen, setisOpen] = useState(false);
  const [fullNameErrorState, setfullNameErrorState] = useState(false);
  const [emailErrorState, setemailErrorState] = useState(false);
  const [industryErrorState, setindustryErrorState] = useState(false);
  const [selectText, setselectText] = useState(``);
  const [touched, setTouched] = useState(false);
  const [selectItems, setSelectItems] = useState<any>({
    'Marketing': false,
    'Business': false,
    'Product': false,
    'Design': false,
    'Tech': false,
    'Data': false,
    'Generative AI': false
  });
  const [showSuccessModal, setshowSuccessModal] = useState(false);
  const [apiErrorState, setapiErrorState] = useState(false);
  const handleSearchInputChange = (event: any) => {
    setSearchQuery(event.target.value.trim());
  };
  const filteredTimeZones = timeZoneData.map(timezone => ({
    ...timezone,
    items: timezone.items.filter((item: any) => item.value.toLowerCase().includes(searchQuery.toLowerCase()))
  }));
  const pathanme = usePathname();
  const isMobile = useViewType();
  const handleFullName = (e: any) => {
    let value = e.target.value.trim();
    if (!value) {
      setfullNameErrorState(true);
    } else {
      setfullNameErrorState(false);
    }
    setFullName(value);
  };
  const handleEmail = (e: any) => {
    let value = e.target.value.trim();
    if (!value) {
      setemailErrorState(true);
    } else {
      setemailErrorState(false);
    }
    setfooterInputText(value);
  };
  const handleTimeZoneClick = (timeZone: TimeZone) => {
    setTimeZoneItem(timeZone);
    setSelectedTimeZone(timeZone);
  };
  const getTimeZoneText = () => {
    if (!timeZoneItem) return '';
    let [utc] = timeZoneItem.text.split(" ");
    return `${timeZoneItem.value} ${utc} hrs`;
  };
  const handleSubmit = () => {
    let flag = false;
    if (!footerInputText) {
      setemailErrorState(true);
      flag = true;
    }
    if (!fullName) {
      setfullNameErrorState(true);
      flag = true;
    }
    if (!Object.keys(selectItems).filter((item: string) => selectItems[item]).length) {
      setindustryErrorState(true);
      flag = true;
    }
    if (!flag) {
      fetch("https://hooks.zapier.com/hooks/catch/11647787/bv0hl0g/", {
        method: "POST",
        body: JSON.stringify({
          fullName,
          footerInputText,
          selected: Object.keys(selectItems)
        })
      }).then((data: any) => setshowSuccessModal(true)).catch((error: any) => setapiErrorState(true));
    }
  };
  useEffect(() => {
    if (touched && !selectText) {
      setindustryErrorState(true);
    } else {
      setindustryErrorState(false);
    }
  }, [isOpen]);
  const handleSelect = (item: string, index: number) => {
    let itemsPrev: any = {
      ...selectItems
    };
    itemsPrev[item] = !itemsPrev[item];
    let selectedCategories = Object.keys(itemsPrev).filter((item: string) => itemsPrev[item]).length;
    setSelectItems(itemsPrev);
    setselectText(`${selectedCategories} ${selectedCategories > 1 ? `categories` : `category`} selected`);
  };
  return <>
      {isHomePage ? <>

        

      </> : <>

        <div className="footer" style={{
        marginTop: pathanme.includes("industry") && isMobile ? "-70px" : ""
      }} suppressHydrationWarning={true}>
          <div className="footer_main_content">
            <div className="left_content">
              <div className="text_items_wrapper">
                <TypoGraphy text={projectStrings.footerHeading} variant={isMobile ? 'Mobile/Heading 1/S | 20 | 120' : 'Desktop/Heading 1/S | 20 |120'} tagName='h4' color='#fff' />
                <TypoGraphy text={projectStrings.footerText} variant={isMobile ? 'Mobile/Body 2' : 'Desktop/Body 1/R | 16 |160'} styles={{
                opacity: "0.5"
              }} color='#fff' />
                <div className="location" onClick={() => setShowModal(true)}>
                  <Image loader={gumletLoader} width={12} height={13} src={projectImages.earthIcon} alt="globe" />  <TypoGraphy text={getTimeZoneText()} variant='Desktop/Body 1/R | 16 |120' color='#fff' />
                </div>
              </div>
              <div className="middle_content_wrapper">
                <div className="input_wrapper">
                  <input type="email" value={footerInputText} onChange={e => setfooterInputText(e.target.value)} placeholder='Type your Email' />
                </div>
                <div className="subscribe_wrapper">
                  <SubscribeButton clickHandler={() => setNewsModal(true)} />
                </div>
              </div>
              <div className="logos_wrapper">
                <div className="social_media_logos">
                  {socialMediaIcons.map((item: any, index: number) => <a href={item?.link} onClick={e => {
                  e.preventDefault();
                  window.open(item?.link);
                }} key={`image_${index}`}><Image loader={gumletLoader} width={32} height={32} src={item?.image} alt='social_media' /></a>)}
                </div>
                <div className="growthschool_logo">
                  <Image loader={gumletLoader} width={113} height={48} src={projectImages.GSLogoFullgswhite} alt="growth_school" />
                </div>
              </div>
            </div>
            <div className="location_mobile" onClick={() => setmobileModal(true)}>
              <Image loader={gumletLoader} width={20} height={21} src={projectImages.earthIcon} alt="globe" />  <TypoGraphy text={getTimeZoneText()} variant='Desktop/Body 1/R | 16 |120' color='#fff' />
            </div>
            <div className="right_content">
              <div className="footer_data_wrapper">
                {footerData?.map((footerItem: any, index: number) => {
                return <div className="footer_item" key={`footer_item_${index}`}>
                      <TypoGraphy tagName='h4' text={footerItem.name} variant={isMobile ? 'Mobile/Heading 1/S | 20 | 160' : 'Desktop/Heading 1/S | 20 |120'} color='#fff' />
                      <div className="footer_items_wrapper">
                        {footerItem.items.map((item: any, index: number) => {
                      return <a href={item?.link} className="footer_sub_item" key={`footer_sub_item_${index}`}>
                              <TypoGraphy text={item?.name} variant='Desktop/Body 1/M | 16 |120' styles={{
                          opacity: "0.5"
                        }} color='#fff' />
                            </a>;
                    })}
                      </div>
                    </div>;
              })}
              </div>
            </div>
          </div>
          <div className="footer_hr"></div>
          <div className="footer_last_text">
            <TypoGraphy text={projectStrings.footerRights} variant='Desktop/Body 1/R | 16 |120' styles={{
            opacity: "0.5"
          }} color='white' />
          </div>
        </div>
        {showModal && <NormalModal clickHandler={() => setShowModal(false)}>
          <div className="time_zones_wrapper">
            {filteredTimeZones.map((timezone: any, index: number) => {
            return <div className="timezone_wrapper" key={`timezone_index_${index}`}>
                  <div className="title_wrapper">
                    <TypoGraphy text={timezone.title} variant='Desktop/Body 1/M | 16 |120' />
                    {index === 1 && <div className="search_time_zone">
                      <input onChange={handleSearchInputChange} type="text" placeholder='Search your Timezone' /><img src={projectImages.searchIcon} alt='search-time-zone' />
                    </div>}
                  </div>
                  <div className="divider_line"></div>
                  <div className="timezone_items">
                    {timezone.items.slice(0, 9).map((item: TimeZone, index: number) => {
                  return <div className={item.abbr === timeZoneItem.abbr ? "timezone_item_active" : "timezone_item"} key={`timezone_${index}`} onClick={() => handleTimeZoneClick(item)}>
                        <TypoGraphy text={item.value} variant='Desktop/Body 2/R | 14 |120' />
                      </div>;
                })}
                  </div>
                </div>;
          })}
          </div>
        </NormalModal>}
        {mobileModal && <MobileModal closehandler={() => {
        setmobileModal(false);
      }}>
            <div className="mobile_time_zone_modal_content">
              <div className="top_section">
                <TypoGraphy text='Update Timezone' variant='Desktop/Heading 1/M | 20 |120' />
                <TypoGraphy text={getTimeZoneText()} variant='Desktop/Body 2/R | 14 |120' />
              </div>
              <div className="serach_section">
                <div className="search_time_zone">
                  <input onChange={e => setsearchText(e.target.value)} value={searchText} type="text" placeholder='Search' /><img src={projectImages.searchIcon} alt='search-time-zone' />
                </div>
                <div className="timezones_list_wrapper">
                  <div className="heading_sec">
                    <TypoGraphy text='Time Zone' variant='Desktop/Body 2/M | 14 |120' />
                  </div>
                  <div className="list_items">
                    {timezones.filter((timezone: TimeZone) => {
                  if (!searchText) return true;
                  return timezone.text.toLowerCase().includes(searchText?.toLowerCase());
                }).map((timezone: TimeZone, index: number) => <TypoGraphy text={timezone.text} variant='Desktop/Body 2/R | 14 |120' key={`timezone_item_${index}`} color='#030A21' clickHandler={() => handleTimeZoneClick(timezone)} />)}
                  </div>
                </div>
              </div>
            </div>
          </MobileModal>}
        {newsModal && <NormalModal clickHandler={() => {
        if (showSuccessModal) setshowSuccessModal(false);
        setFullName('');
        setselectText('');
        setTouched(false);
        setSelectItems({
          'Marketing': false,
          'Business': false,
          'Product': false,
          'Design': false,
          'Tech': false,
          'Data': false,
          'Generative AI': false
        });
        setapiErrorState(false);
        setNewsModal(false);
      }}>
          {showSuccessModal ? <div className="success_modal">
            <svg xmlns="http://www.w3.org/2000/svg" width="61" height="60" viewBox="0 0 61 60" fill="none">
              <circle cx="30" cy="29.5" r="15.5" fill="#030A21" />
              <path d="M30.5 5C16.725 5 5.5 16.225 5.5 30C5.5 43.775 16.725 55 30.5 55C44.275 55 55.5 43.775 55.5 30C55.5 16.225 44.275 5 30.5 5ZM42.45 24.25L28.275 38.425C27.925 38.775 27.45 38.975 26.95 38.975C26.45 38.975 25.975 38.775 25.625 38.425L18.55 31.35C17.825 30.625 17.825 29.425 18.55 28.7C19.275 27.975 20.475 27.975 21.2 28.7L26.95 34.45L39.8 21.6C40.525 20.875 41.725 20.875 42.45 21.6C43.175 22.325 43.175 23.5 42.45 24.25Z" fill="#37ED81" />
            </svg>
            <div className="heading_one">
              <TypoGraphy text='Welcome aboard' variant={isMobile ? 'Mobile/Heading 1/S | 20 | 120' : 'Desktop/Heading 1/M | 20 |120'} /></div>
            <div className="sub_text">
              <TypoGraphy text="We're absolutely thrilled that you've decided to subscribe to our newsletter!" variant={isMobile ? 'Mobile/Body 2/R | 14 | 160' : 'Desktop/Body 2/R | 14 | 160'} /></div>
            <Button text='Read Past Newsletters' variant={'NormalVariant'} clickHandler={() => window.open('https://home.growthschool.io/newsletter-vault')} textColor='white' backgroundColor='black' textVariant={isMobile ? 'Mobile/Body 1/M | 16 | 120' : 'Desktop/Body 1/M | 16 |120'} />
          </div> : <div className="news_modal">
            <div className="top_section_content">
              <div className="heading_one">
                <TypoGraphy text='Top 1%' variant={'Top 1'} />
                <svg xmlns="http://www.w3.org/2000/svg" width="27" height="26" viewBox="0 0 27 26" fill="none">
                  <path d="M23.0462 21.1323V18.3328L15.81 25.6833C15.0041 25.8508 14.1699 25.9389 13.3152 25.9389C12.0063 25.9389 10.7449 25.7325 9.56071 25.3495C4.56458 23.7352 0.945312 18.9839 0.945312 13.3738C0.945312 6.4341 6.4834 0.808594 13.3152 0.808594C19.3465 0.808594 24.3693 5.19266 25.4638 10.9948C25.6089 11.7651 25.6851 12.5602 25.6851 13.3738C25.6851 16.3019 24.6992 18.9959 23.0462 21.1323Z" fill="#056A67" />
                  <path d="M26.7735 10.9941V24.7991H23.0461V18.3322L15.8098 25.6826C15.004 25.8502 14.1697 25.9383 13.3151 25.9383C12.0062 25.9383 10.7448 25.7319 9.56055 25.3489L19.9648 14.7804H12.6883V10.9941H26.7735Z" fill="#37ED81" />
                </svg>
              </div>
              <TypoGraphy text='NEWSLETTER' variant={'Newsletter'} />
              <div className="sub_heading_content">
                <TypoGraphy text='Stay up to date with our weekly newsletter!' styles={{
                textAlign: "center"
              }} variant={isMobile ? 'Mobile/Body 2/R | 14 | 120' : 'Desktop/Body 2/R | 14 |120'} />
              </div>
            </div>
            <div className="middle_sec">
              <div className={fullNameErrorState ? "input_wrapper error_state" : "input_wrapper"}>
                <label htmlFor='fullname'>
                  <TypoGraphy text='Full Name' variant={isMobile ? 'Mobile/Body 1/M | 16 | 120' : 'Desktop/Body 1/M | 16 |120'} />
                  <TypoGraphy text='*' variant={isMobile ? 'Mobile/Body 1/M | 16 | 120' : 'Desktop/Body 1/M | 16 |120'} color='#DB0000' />
                </label>
                <input type="text" name='fullname' placeholder='Enter your name' value={fullName} onChange={handleFullName} />
                {fullNameErrorState && <TypoGraphy text='Enter your name' variant={'Mobile/Body 2/R | 14 | 120'} color='#DB0000' />}
              </div>
              <div className={emailErrorState ? "input_wrapper error_state" : "input_wrapper"}>
                <label htmlFor='email'>
                  <TypoGraphy text='Email' variant={isMobile ? 'Mobile/Body 1/M | 16 | 120' : 'Desktop/Body 1/M | 16 |120'} />
                  <TypoGraphy text='*' variant={isMobile ? 'Mobile/Body 1/M | 16 | 120' : 'Desktop/Body 1/M | 16 |120'} color='#DB0000' />
                </label>
                <input type="email" name='email' placeholder='Enter your email' value={footerInputText} onChange={handleEmail} />
                {emailErrorState && <TypoGraphy text='Enter your email' variant={'Mobile/Body 2/R | 14 | 120'} color='#DB0000' />}
              </div>
              <div className={industryErrorState ? "input_wrapper select error_state" : "input_wrapper select"}>
                <label htmlFor='news'>
                  <TypoGraphy text='News Letter Category' variant={isMobile ? 'Mobile/Body 1/M | 16 | 120' : 'Desktop/Body 1/M | 16 |120'} />
                  <TypoGraphy text='*' variant={isMobile ? 'Mobile/Body 1/M | 16 | 120' : 'Desktop/Body 1/M | 16 |120'} color='#DB0000' />
                </label>
                <div className={isOpen ? "select_wrapper select_open" : "select_wrapper"} onClick={() => {
                if (!touched) {
                  setTouched(true);
                }
                setisOpen(!isOpen);
              }}>
                  <input type="text" placeholder='Select Industry' name='news' value={selectText} onChange={e => e.preventDefault()} />
                  {!isOpen ? <svg xmlns="http://www.w3.org/2000/svg" width="17" height="13" viewBox="0 0 17 13" fill="none">
                    <g clip-path="url(#clip0_4459_9503)">
                      <path d="M3.409 4.17187L8.49991 9.82844L13.5908 4.17188" stroke="black" strokeWidth="0.85" strokeLinecap="round" strokeLinejoin="round" />
                    </g>
                    <defs>
                      <clipPath id="clip0_4459_9503">
                        <rect width="13" height="17" fill="white" transform="translate(17) rotate(90)" />
                      </clipPath>
                    </defs>
                  </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="17" height="13" viewBox="0 0 17 13" fill="none">
                    <g clip-path="url(#clip0_3779_41564)">
                      <path d="M3.409 8.82813L8.49991 3.17156L13.5908 8.82812" stroke="black" strokeWidth="0.85" strokeLinecap="round" strokeLinejoin="round" />
                    </g>
                    <defs>
                      <clipPath id="clip0_3779_41564">
                        <rect width="13" height="17" fill="white" transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 17 13)" />
                      </clipPath>
                    </defs>
                  </svg>}
                </div>
                {isOpen && <div className="open_state">
                  {Object.keys(selectItems).map((item: string, index: number) => {
                  return <div className={selectItems[item] === true ? "select_item_wrapper selected" : "select_item_wrapper"} key={`select_item_${index}`} onClick={() => handleSelect(item, index)}>
                        <TypoGraphy text={item} variant={isMobile ? 'Mobile/Body 2/R | 14 | 120' : 'LP/Paragraph/Paragraph Small'} />
                      </div>;
                })}
                </div>}
                {industryErrorState && touched && <TypoGraphy text='Select a category to get newsletter' variant={'Mobile/Body 2/R | 14 | 120'} color='#DB0000' />}
              </div>
              <div className="buttn_wrapper">
                <Button text='Subscribe' textVariant={isMobile ? 'Mobile/Body 1/M | 16 | 120' : 'Desktop/Body 1/M | 16 |120'} textColor='white' backgroundColor='black' variant='NormalVariant' clickHandler={handleSubmit} />
              </div>
            </div>
            {apiErrorState && <div className="api_call_failed_state">
              <TypoGraphy text='Something went wrong' variant={'Mobile/Body 2/R | 14 | 120'} color='#DB0000' />
            </div>}
            <div className="let_me" onClick={() => window.open('https://home.growthschool.io/newsletter-vault')}>
              <TypoGraphy text='Let me read it first' variant='Desktop/Body 2/M | 14 |120' />
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 12 11" fill="none">
                <path d="M4.05566 9L7.94455 5.5L4.05566 2" stroke="black" strokeWidth="0.85" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
          </div>}
        </NormalModal>}
      </>}</>;
};
export default Footer;