import { projectImages } from '../../../../public/Assets/Images';
import ModalDarkScreen from '../ModalDarkScreen';
import './index.scss';
const NormalModal = (props: any) => {
  return <ModalDarkScreen styles={{
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }} closeOnClick closehandler={props.clickHandler} data-sentry-element="ModalDarkScreen" data-sentry-component="NormalModal" data-sentry-source-file="index.tsx">
            <div className="normal_modal_wrapper">
                <div className="close_icon_wrapper">
                    <img src={projectImages.closeIconSmall} alt="close" loading='lazy' onClick={() => props.clickHandler()} />
                </div>
                <div className="normal_modal_content_wrapper">
                    {props.children}
                </div>
            </div>
        </ModalDarkScreen>;
};
export default NormalModal;