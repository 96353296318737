"use client";

import FooterNew from "@/components/new/Footer/Footer";
import NavbarNew from "@/components/new/Navbar/Navbar";
import Footer from "@/elements/Footer";
import Navbar from "@/elements/Navbar";
import { getSlugAndRouteName } from "@/utils/Functions";
import { usePathname } from "next/navigation";
const ConditionalLayout = ({
  children
}: any) => {
  const pathname = usePathname();
  const {
    isPremium,
    isIndustryPage,
    isHome
  } = getSlugAndRouteName(pathname);
  return <>
      {isPremium || isIndustryPage || isHome ? <NavbarNew /> : <Navbar />}
      {children}
      {isPremium || isIndustryPage || isHome ? <FooterNew /> : <Footer />}
    </>;
};
export default ConditionalLayout;