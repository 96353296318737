"use client";

import Script from "next/script";
import { useContext } from "react";
import * as pixel from "../utils/fpixel";
import { AppContext } from "@/contexts/appcontext";
const FacebookPixel = () => {
  const {
    setPixelLoaded
  } = useContext<any>(AppContext);
  return <div data-sentry-component="FacebookPixel" data-sentry-source-file="FacebookPixel.tsx">
      <Script id="fb-pixel" src="/scripts/pixel.js" strategy="afterInteractive" onLoad={() => setPixelLoaded(true)} data-pixel-id={pixel.FB_PIXEL_ID} data-sentry-element="Script" data-sentry-source-file="FacebookPixel.tsx" />
    </div>;
};
export default FacebookPixel;