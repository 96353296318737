import { Industry, LearnItem } from "@/interfaces";
import { projectImages } from "../../public/Assets/Images";

export const industries: Array<Industry> = [{
    industryImage: projectImages.productIcon,
    industryName: 'Product',
  }, {
    industryImage: projectImages.PenIcon,
    industryName: "Design",
  }, {
    industryImage: projectImages.marketingIcon,
    industryName: "Marketing"
  }, {
    industryImage: projectImages.businessIcon,
    industryName: "Business"
  }, {
    industryImage: projectImages.dataIcon,
    industryName: "Data"
  }, {
    industryImage: projectImages.techIcon,
    industryName: "Tech"
  }];
  
 export const dummyTestimonials = [{
    image: "",
    name: "Kavya Jeevakumar",
    designation: 'Product Designer, Apna',
    desc: 'Lorem ipsum dolor sit amet consectetur. Pretium senectus massa duis felis arcu accumsan tincidunt. Id volutpat risus orci eget lacus ornare. Ullamcorper vel blandit odio eu mauris montes nunc tellus.  tincidunt. Id volutpat ...'
  }, {
    image: "",
    name: "Kavya Jeevakumar",
    designation: 'Product Designer, Apna',
    desc: 'Lorem ipsum dolor sit amet consectetur. Pretium senectus massa duis felis arcu accumsan tincidunt. Id volutpat risus orci eget lacus ornare. Ullamcorper vel blandit odio eu mauris montes nunc tellus.  tincidunt. Id volutpat ...'
  }, {
    image: "",
    name: "Kavya Jeevakumar",
    designation: 'Product Designer, Apna',
    desc: 'Lorem ipsum dolor sit amet consectetur. Pretium senectus massa duis felis arcu accumsan tincidunt. Id volutpat risus orci eget lacus ornare. Ullamcorper vel blandit odio eu mauris montes nunc tellus.  tincidunt. Id volutpat ...'
  }, {
    image: "",
    name: "Kavya Jeevakumar",
    designation: 'Product Designer, Apna',
    desc: 'Lorem ipsum dolor sit amet consectetur. Pretium senectus massa duis felis arcu accumsan tincidunt. Id volutpat risus orci eget lacus ornare. Ullamcorper vel blandit odio eu mauris montes nunc tellus.  tincidunt. Id volutpat ...'
  }, {
    image: "",
    name: "Kavya Jeevakumar",
    designation: 'Product Designer, Apna',
    desc: 'Lorem ipsum dolor sit amet consectetur. Pretium senectus massa duis felis arcu accumsan tincidunt. Id volutpat risus orci eget lacus ornare. Ullamcorper vel blandit odio eu mauris montes nunc tellus.  tincidunt. Id volutpat ...'
  }]
  
 export const successStories = [{
    "linkedIn": "linkedin.com/in/jay-mota",
    "program": "Performance Mkt.",
    "name": "Jay Mota",
    "imgUrl": "https://assets.growthschool.io/user-testimonial-images/Jay_Mota.jpg?response-content-disposition=inline",
    "jobShiftTime": "9 months",
    "previousCompany": "Atom Consultancy",
    "currentCompany": "Atom Foods",
    "currentRole": "Founder",
    "previousRole": ""
  },
  {
    "linkedIn": "linkedin.com/in/jay-mota",
    "program": "Performance Mkt.",
    "name": "Jay Mota",
    "imgUrl": "https://assets.growthschool.io/user-testimonial-images/Jay_Mota.jpg?response-content-disposition=inline",
    "jobShiftTime": "9 months",
    "previousCompany": "Atom Consultancy",
    "currentCompany": "Atom Foods",
    "currentRole": "Founder",
    "previousRole": ""
  }, {
    "linkedIn": "linkedin.com/in/jay-mota",
    "program": "Performance Mkt.",
    "name": "Jay Mota",
    "imgUrl": "https://assets.growthschool.io/user-testimonial-images/Jay_Mota.jpg?response-content-disposition=inline",
    "jobShiftTime": "9 months",
    "previousCompany": "Atom Consultancy",
    "currentCompany": "Atom Foods",
    "currentRole": "Founder",
    "previousRole": ""
  }, {
    "linkedIn": "linkedin.com/in/jay-mota",
    "program": "Performance Mkt.",
    "name": "Jay Mota",
    "imgUrl": "https://assets.growthschool.io/user-testimonial-images/Jay_Mota.jpg?response-content-disposition=inline",
    "jobShiftTime": "9 months",
    "previousCompany": "Atom Consultancy",
    "currentCompany": "Atom Foods",
    "currentRole": "Founder",
    "previousRole": ""
  }, {
    "linkedIn": "linkedin.com/in/jay-mota",
    "program": "Performance Mkt.",
    "name": "Jay Mota",
    "imgUrl": "https://assets.growthschool.io/user-testimonial-images/Jay_Mota.jpg?response-content-disposition=inline",
    "jobShiftTime": "9 months",
    "previousCompany": "Atom Consultancy",
    "currentCompany": "Atom Foods",
    "currentRole": "Founder",
    "previousRole": ""
  }]
  
 export const dummyFilters = ["Performance Marketing",
    "Brand Marketing",
    "Growth Hacking",
    "B2B Marketing",
    "Personal Brand"
  ]
  
export const videoTestimonial = [{
    image: '',
    name: "Kavya Jeevakumar",
    designation: 'Product Designer, Apna',
  
  }, {
    image: '',
    name: "Kavya Jeevakumar",
    designation: 'Product Designer, Apna',
  
  }, {
    image: '',
    name: "Kavya Jeevakumar",
    designation: 'Product Designer, Apna',
  
  }, {
    image: '',
    name: "Kavya Jeevakumar",
    designation: 'Product Designer, Apna',
  
  }, {
    image: '',
    name: "Kavya Jeevakumar",
    designation: 'Product Designer, Apna',
  
  }, {
    image: '',
    name: "Kavya Jeevakumar",
    designation: 'Product Designer, Apna',
  
  }, {
    image: '',
    name: "Kavya Jeevakumar",
    designation: 'Product Designer, Apna',
  
  }, {
    image: '',
    name: "Kavya Jeevakumar",
    designation: 'Product Designer, Apna',
  
  }]
  
export const freeCoursesFaqs = [ 

  {
    question : 'What topics are covered in the career resources?',
    answer : "Topics range from marketing to technology, offering insights from industry experts on various career paths and skill development opportunities."
  }, {
    question : 'How can I access the free resources?',
    answer : "Simply visit the website and browse the articles. All resources are freely available for anyone interested in advancing their career knowledge."
  }, {
    question : 'Are the resources suitable for beginners?',
    answer : "Yes, the articles cater to beginners and experienced professionals alike, providing valuable insights and guidance for career growth at all levels."
  }, {
    question : 'Who are the industry experts contributing to the resources?',
    answer : "Industry experts from diverse fields share their knowledge, ensuring a comprehensive range of perspectives and insights in the career resources."
  }, {
    question : 'Can I find tips for job searching and professional development?',
    answer: ' Absolutely, the resources cover topics such as resume writing, interview preparation, networking, and continuous learning to support your career advancement journey.'
  }];

export const topicsFaqs = [
  {
    question : 'How can these articles benefit me?',
    answer : 'They provide valuable insights and resources to enhance your career, offering guidance from industry experts to help you navigate your professional journey and achieve your goals.'
  },
  {
    question : 'Are the resources free to access?',
    answer : 'Yes, all articles are freely available online, allowing you to learn and grow without any cost barriers, making professional development accessible to everyone.'
  },
  {
    question : 'Who are the industry experts contributing to these articles?',
    answer : 'The articles are authored by seasoned professionals and thought leaders in their respective fields, ensuring you receive credible and reliable advice from experienced individuals.'
  }
]


export const learnItems: Array<LearnItem> = [{
  name : "Marketing",
  icon : projectImages.learnDownDark,
  url : 'https://home.growthschool.io/industry/marketing'
}, {
  name : "Generative AI",
  url : 'https://home.growthschool.io/industry/generative-ai'
}, {
  name : "Product",
  url : 'https://home.growthschool.io/industry/product'
}, {
  name : "Design",
  url : 'https://home.growthschool.io/industry/design'
}, {
  name : "Business",
  url : 'https://home.growthschool.io/industry/business'
}, {
  name : "Tech & Data",
  url : 'https://home.growthschool.io/industry/tech-and-data'
}] 

export const growthItems: Array<LearnItem> = [{
  name : "For Personal Growth",
  url : 'https://home.growthschool.io/industry/personal-growth'
}, {
  name : 'For Founders',
  url : 'https://home.growthschool.io/industry/founders'
}]