import React from 'react';
import './index.scss';
import ModalDarkScreen from '../ModalDarkScreen';
import { projectImages } from '../../../../public/Assets/Images';
const MobileModal = (props: any) => {
  return <ModalDarkScreen styles={{
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center"
  }} closehandler={props.closehandler} closeOnClick={true} data-sentry-element="ModalDarkScreen" data-sentry-component="MobileModal" data-sentry-source-file="index.tsx">
        <div className="mobile_modal_content">
          <div className="close_icon_wrapper">
            <img src={projectImages.closeiconmedium} alt="close" onClick={props.closehandler} loading='lazy' />
          </div>
          <div className="modal_contentt">
            {props.children}
          </div>
        </div>
    </ModalDarkScreen>;
};
export default MobileModal;