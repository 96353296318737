"use client";

import React, { useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
const ModalComponent = ({
  children,
  headingIcon,
  heading,
  onClose,
  subheading,
  size
}: {
  children?: React.ReactNode;
  headingIcon?: React.ReactNode;
  heading: string;
  onClose: () => void;
  subheading?: string;
  size?: "small" | "medium" | "large";
}) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);
  return <AnimatePresence mode="wait" data-sentry-element="AnimatePresence" data-sentry-component="ModalComponent" data-sentry-source-file="Modal.tsx">
    <div className={`section__modal ${size ? size : ""}`}>
      <div className="section__modal-overlay" onClick={onClose}></div>
      <motion.div initial={{
        y: "100%"
      }} animate={{
        y: 0
      }} transition={{
        duration: 0.2
      }} exit={{
        y: "100%"
      }} className="section__modal-container" data-sentry-element="unknown" data-sentry-source-file="Modal.tsx">
        <div className="section__modal-header">
          <div className="section__modal-header-container">
            <div className="section__modal-header-text">
              {headingIcon}
              <h2>{heading}</h2>
              {subheading && <p className="section__modal-header-subheading">{subheading}</p>}
            </div>
            <button onClick={onClose} className="section__modal-close">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none" data-sentry-element="svg" data-sentry-source-file="Modal.tsx">
                <path d="M1.00049 13.4991L12.9996 1.5" stroke="#002726" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" data-sentry-element="path" data-sentry-source-file="Modal.tsx" />
                <path d="M12.9996 13.4991L1.00049 1.5" stroke="#002726" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" data-sentry-element="path" data-sentry-source-file="Modal.tsx" />
              </svg>
            </button>
          </div>
        </div>
        <div className="section__modal-content">{children}</div>
      </motion.div>
    </div>
      </AnimatePresence>;
};
export default ModalComponent;