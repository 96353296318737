import React, { useEffect } from 'react';
import './index.scss';
const ModalDarkScreen = (props: any) => {
  const handleBodyClick = (e: any) => {
    if (e.target.className === 'modal_dark_screen' && props.closeOnClick && props.closehandler) {
      props.closehandler();
    }
  };
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'scroll';
    };
  }, []);
  return <div className="modal_dark_screen" style={props?.styles} onClick={handleBodyClick} data-sentry-component="ModalDarkScreen" data-sentry-source-file="index.tsx">
        <div className="modal_content">
            {props.children}
        </div>
    </div>;
};
export default ModalDarkScreen;