"use client";

import { motion, useMotionValueEvent, useScroll } from "framer-motion";
import Image from "next/image";
import Link from "next/link";
import React, { useCallback, useEffect, useState } from "react";
import GSIconArrowRight from "../../../../public/images/new/GS-Icon-Arrow-Right.svg";
import GSIconColumn from "../../../../public/images/new/GS-Icon-Column.svg";
import GSFullLogo from "../../../../public/images/new/GS-Logo-Full.svg";
import GSIconLive from "../../../../public/images/new/GS-Icon-Live.svg";
import GSIconCalender from "../../../../public/images/new/GS-Icon-Calender.svg";
import { Industry, Program, StandardLP } from "@/services/types";
import { formatStringArray } from "@/utils/Functions";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import { useQuery } from "@tanstack/react-query";
import { setItem } from "@/utils/Functions/storageUtils";
import BG1 from "../../../../public/images/new/GS-BG-NAV-1.png";
import BG2 from "../../../../public/images/new/GS-BG-NAV-2.png";
import BG3 from "../../../../public/images/new/GS-BG-NAV-3.png";
import MobileMenu from "./Mobile-Menu";
import ModalComponent from "../Common/Modal";
import transformStrings from "@/utils/Functions/transformSessionType";
import { fetchDiscoveryData, fetchIndustriesData } from "@/services/external";
import { routeVariables, sanitizeUrlSegment } from "@/utils/routestrings";
import { formatDateNormal } from "@/utils/Functions/formatDate";
import { CheckLoginStatus } from "@/utils/Hooks/checkLoginStatus";
export default function Navbar() {
  const [mobileIsOpen, setMobileIsOpen] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const {
    data: learnerData,
    isLoading: isLearnerDataLoading
  } = useQuery<Program[]>({
    queryKey: ["learner-api"],
    queryFn: () => fetchDiscoveryData()
  });
  const {
    data: industries
  } = useQuery<Industry[]>({
    queryKey: ["industries"],
    queryFn: () => fetchIndustriesData()
  });
  const [programs, setPrograms] = useState<{
    [key: number]: StandardLP[];
  } | undefined>();
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(-1);
  useEffect(() => {
    if (industries) {
      // Map industries to programs
      let temp: {
        [key: number]: StandardLP[];
      } = {};
      for (let industry of industries) {
        temp[industry.id] = [];
        let subindustries = industry.attributes.premium_subindustries.data;
        if (!subindustries) continue;
        for (let subindustry of subindustries) {
          let standard_lps = subindustry.attributes.standard_lps.data;
          if (!standard_lps) continue;
          for (let program of standard_lps) {
            temp[industry.id].push(program);
          }
        }
      }

      // Remove duplicate programs from each industry
      for (let industryId in temp) {
        const seenProductIds = new Set();
        temp[industryId] = temp[industryId].filter(program => !seenProductIds.has(program.attributes.productId) && seenProductIds.add(program.attributes.productId));
      }
      setPrograms(temp);
    }
  }, [industries]);
  const [discoveryPrograms, setDiscoveryPrograms] = useState<{
    [key: number]: Program;
  } | undefined>();
  useEffect(() => {
    if (learnerData && learnerData.length > 0) {
      // Map productIds to discovery programs, to easily fetch discovery program data of a standardlp
      let temp: {
        [key: number]: Program;
      } = {};
      for (let program of learnerData) {
        temp[program.product_id] = program;
      }
      setDiscoveryPrograms(temp);
    }
  }, [learnerData]);
  const mentorshipTypes = ["Intensive Program", "Career Accelerator Program"];
  const selfPacedTypes = ["OnDemand"];
  const filterByProgramTypes = (programTypes: any, index: number) => {
    return programs && discoveryPrograms && programs[index] ? programs[index].filter(item => programTypes.includes(discoveryPrograms[item.attributes.productId].programType)).map(item => discoveryPrograms[item.attributes.productId]) : [];
  };
  const filterByProgramTypesStandardLP = (programTypes: any, index: number) => {
    return programs && discoveryPrograms && programs[index] ? programs[index].filter(item => programTypes.includes(discoveryPrograms[item.attributes.productId].programType)) : [];
  };
  const mentorShipPrograms = selectedTabIndex === -1 ? [] : filterByProgramTypes(mentorshipTypes, selectedTabIndex);
  const selfPacedPrograms = selectedTabIndex === -1 ? [] : filterByProgramTypes(selfPacedTypes, selectedTabIndex);
  const mentorshipStandardLps = selectedTabIndex === -1 ? [] : filterByProgramTypesStandardLP(mentorshipTypes, selectedTabIndex);
  const selfPacedStandardLps = selectedTabIndex === -1 ? [] : filterByProgramTypesStandardLP(selfPacedTypes, selectedTabIndex);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isButtonHovered, setIsButtonHovered] = useState(false);
  const {
    scrollY
  } = useScroll();
  const [hidden, setHidden] = useState(false);
  const [prevScroll, setPrevScroll] = useState(0);
  function update(latest: number, prev: number): void {
    if (latest < prev) {
      setHidden(false);
    } else if (latest > 100 && latest > prev) {
      setHidden(true);
    }
  }
  useMotionValueEvent(scrollY, "change", (latest: number) => {
    update(latest, prevScroll);
    setPrevScroll(latest);
  });
  useEffect(() => {
    if (isOpen || mobileIsOpen) {
      // setSelectedTabIndex(industries ? industries[0].id : 0);
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen, mobileIsOpen]);

  // hook to close the pop up when esc is pressed
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setIsOpen(false);
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  const handleMouseEnter = useCallback(() => {
    setIsButtonHovered(true);
  }, []);
  const handleMouseLeave = useCallback(() => {
    setIsButtonHovered(false);
  }, []);
  const handleLogoClick = () => {
    setMobileIsOpen(false);
    setIsOpen(false);
  };
  const {
    data: checkLogin,
    isLoading: isLoginLoading
  } = CheckLoginStatus();
  return <motion.nav className="section__navbar" animate={{
    pointerEvents: hidden ? "none" : "all",
    opacity: hidden ? 0 : 1
  }} transition={{
    ease: [0.1, 0.25, 0.3, 1],
    duration: 0.4,
    staggerChildren: 0.05
  }} data-sentry-element="unknown" data-sentry-component="Navbar" data-sentry-source-file="Navbar.tsx">
      <div className="section__navbar-container section__container">
        <div className="section__navbar-left">
          <Link onClick={handleLogoClick} href="/" className="section__navbar-logo" data-sentry-element="Link" data-sentry-source-file="Navbar.tsx">
            <Image src={GSFullLogo} alt="logo" width={100} height={100} loading="lazy" data-sentry-element="Image" data-sentry-source-file="Navbar.tsx" />
          </Link>
          <div className={`section__navbar-dropdown-group ${isOpen ? "open" : ""}`}>
            <button onClick={() => {
            setIsOpen(!isOpen);
          }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className="section__navbar-button section__navbar-button--dropdown" style={{}}>
              Learn
              {!isButtonHovered ? <div className="section__hero-banner-svg" style={{
              position: "absolute",
              right: "4px"
            }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="13" viewBox="0 0 12 13" fill="none">
                    <path d="M1 7.11108L6 12L11 7.11108" stroke="#002726" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M1 1L6 5.88889L11 1" stroke="#002726" strokeOpacity="0.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div> : <DotLottieReact src="/images/new/GS-Down-Arrow.lottie" loop autoplay speed={1.5} style={{
              width: "40px",
              height: "40px",
              position: "absolute",
              right: "4px"
            }} />}
            </button>
            <NavbarDropDown setIsOpen={setIsOpen} industries={industries ?? []} mentorshipPrograms={mentorShipPrograms} selfPacedPrograms={selfPacedPrograms} mentorshipStandardLps={mentorshipStandardLps} selfPacedStandardLps={selfPacedStandardLps} selectedTabIndex={selectedTabIndex} setSelectedTabIndex={setSelectedTabIndex} filterByProgramTypes={filterByProgramTypes} filterByProgramTypesStandardLP={filterByProgramTypesStandardLP} data-sentry-element="NavbarDropDown" data-sentry-source-file="Navbar.tsx" />
          </div>
          <Link href="https://home.growthschool.io/b2b" className="text-bottle-green text-base font-medium leading-[120%] no-underline hidden lg:inline" data-sentry-element="Link" data-sentry-source-file="Navbar.tsx">
            Upskill your Team
          </Link>
          <Link href="/in/about-us" className="text-bottle-green text-base font-medium leading-[120%] no-underline hidden lg:inline" data-sentry-element="Link" data-sentry-source-file="Navbar.tsx">
            About
          </Link>
        </div>
        <div className="section__navbar-right">
          <Link href="https://serv-learner-prod.growthschool.io/auth/login" className="section__navbar-button" data-sentry-element="Link" data-sentry-source-file="Navbar.tsx">
            {isLoginLoading ? "Loading..." : checkLogin.isLogin ? "Dashboard" : "Login"}
          </Link>
          <Link href="https://forms.growthschool.io/custom/apply-as-a-mentor-at-growthschool" className="section__navbar-button " data-sentry-element="Link" data-sentry-source-file="Navbar.tsx">
            Apply as Mentor
          </Link>
          <button onClick={() => {
          if (mobileIsOpen) {
            setMobileIsOpen(false);
          }
          setIsOpen(!isOpen);
        }} className="section__navbar-button section__navbar-button--menu">
            Learn
            <div className="section__hero-banner-svg">
              <svg xmlns="http://www.w3.org/2000/svg" width="10" height="13" viewBox="0 0 12 13" fill="none" data-sentry-element="svg" data-sentry-source-file="Navbar.tsx">
                <path d="M1 7.11108L6 12L11 7.11108" stroke="#002726" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Navbar.tsx" />
                <path d="M1 1L6 5.88889L11 1" stroke="#002726" strokeOpacity="0.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Navbar.tsx" />
              </svg>
            </div>
          </button>
          <button onClick={() => {
          if (isOpen) {
            setIsOpen(false);
          }
          setMobileIsOpen(!mobileIsOpen);
        }} className="section__navbar-button section__navbar-button--sidebar">
            <svg width="34" height="35" viewBox="0 0 34 35" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="Navbar.tsx">
              <rect x="0.5" y="0.5" width="33" height="34" rx="7.5" stroke="#002726" data-sentry-element="rect" data-sentry-source-file="Navbar.tsx" />
              <path d="M11 12.5H23" stroke="#002726" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" data-sentry-element="path" data-sentry-source-file="Navbar.tsx" />
              <path d="M11 17.5H23" stroke="#002726" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" data-sentry-element="path" data-sentry-source-file="Navbar.tsx" />
              <path d="M11 22.5H23" stroke="#002726" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" data-sentry-element="path" data-sentry-source-file="Navbar.tsx" />
            </svg>
          </button>
        </div>
      </div>
      <MobileMenu setIsModalOpen={setIsModalOpen} isOpen={mobileIsOpen} setIsOpen={setMobileIsOpen} data-sentry-element="MobileMenu" data-sentry-source-file="Navbar.tsx" />
      {isModalOpen && <ModalComponent heading="Choose Your Membership category" size="small" onClose={() => {
      setIsModalOpen(false);
    }}>
          {/* what do these do */}
          <div className="membership__modal-btns">
            <button className="membership__modal-btn active">
              Marketing Pro
            </button>
            <button className="membership__modal-btn">Design Pro</button>
            <button className="membership__modal-btn">
              Personal Growth Pro
            </button>
            <button className="membership__modal-btn">Gen AI Pro </button>
          </div>
        </ModalComponent>}
    </motion.nav>;
}
function NavbarDropDown({
  setIsOpen,
  industries,
  mentorshipPrograms,
  selfPacedPrograms,
  mentorshipStandardLps,
  selfPacedStandardLps,
  selectedTabIndex,
  setSelectedTabIndex,
  filterByProgramTypes,
  filterByProgramTypesStandardLP
}: {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  industries: Industry[];
  mentorshipPrograms: Program[];
  selfPacedPrograms: Program[];
  mentorshipStandardLps: StandardLP[];
  selfPacedStandardLps: StandardLP[];
  selectedTabIndex: number;
  setSelectedTabIndex: React.Dispatch<React.SetStateAction<number>>;
  filterByProgramTypes: (types: string[], index: number) => Program[];
  filterByProgramTypesStandardLP: (types: string[], index: number) => StandardLP[];
}) {
  const [isColumnOpen, setIsColumnOpen] = useState(false);
  let firstFilterSet = false;
  const mentorshipTypes = ["Intensive Program", "Career Accelerator Program"];
  const selfPacedTypes = ["OnDemand"];
  return <div className="section__navbar-dropdown" data-sentry-component="NavbarDropDown" data-sentry-source-file="Navbar.tsx">
      <div onClick={() => setIsOpen(false)} className="section__navbar-dropdown-overlay"></div>
      <div className={`section__navbar-dropdown-container ${isColumnOpen ? "column--open" : ""}`}>
        <div className={`section__navbar-dropdown-container-header`}>
          <button onClick={() => setIsColumnOpen(false)}>
            <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="Navbar.tsx">
              <path fillRule="evenodd" clipRule="evenodd" d="M7.53038 1.55664C7.82327 1.26374 7.82327 0.788869 7.53038 0.495976C7.23749 0.203083 6.76261 0.203083 6.46972 0.495976L0.996037 5.96966C0.92413 6.04157 0.869876 6.12444 0.833276 6.2129C0.796606 6.30133 0.776367 6.3983 0.776367 6.49999C0.776367 6.60168 0.796606 6.69865 0.833276 6.78708C0.869876 6.87554 0.92413 6.95841 0.996037 7.03032L6.46972 12.504C6.76261 12.7969 7.23749 12.7969 7.53038 12.504C7.82327 12.2111 7.82327 11.7362 7.53038 11.4433L3.33703 7.24999H12.4737C12.8879 7.24999 13.2237 6.9142 13.2237 6.49999C13.2237 6.08578 12.8879 5.74999 12.4737 5.74999H3.33703L7.53038 1.55664Z" fill="#002726" data-sentry-element="path" data-sentry-source-file="Navbar.tsx" />
            </svg>
          </button>
          <button onClick={() => setIsOpen(false)}>
            <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="Navbar.tsx">
              <path d="M1.00049 13.4995L12.9996 1.50043" stroke="#002726" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" data-sentry-element="path" data-sentry-source-file="Navbar.tsx" />
              <path d="M12.9996 13.4995L1.00049 1.50043" stroke="#002726" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" data-sentry-element="path" data-sentry-source-file="Navbar.tsx" />
            </svg>
          </button>
        </div>
        <div className={`section__navbar-dropdown-column section__navbar-dropdown-column--1`}>
          <div className="section__navbar-dropdown-column-group">
            {industries.map((industry, index: number) => {
            if (filterByProgramTypes([...mentorshipTypes, ...selfPacedTypes], industry.id).length > 0) {
              if (!firstFilterSet && selectedTabIndex === -1) {
                setSelectedTabIndex(industry.id);
                firstFilterSet = true;
              }
              return <Column1Link key={index} setIsColumnOpen={setIsColumnOpen} industryId={industry.id} industryName={industry.attributes.name} selectedTab={selectedTabIndex} setSelectedTab={setSelectedTabIndex} />;
            }
          })}
          </div>
          <div className="section__navbar-dropdown-column-content">
            <Link href="/in/courses" className="section__navbar-dropdown-link" onClick={() => setIsOpen(false)} data-sentry-element="Link" data-sentry-source-file="Navbar.tsx">
              Free Certification Courses
            </Link>
            <Link href="/in/topics" className="section__navbar-dropdown-link" onClick={() => setIsOpen(false)} data-sentry-element="Link" data-sentry-source-file="Navbar.tsx">
              Free Topics
            </Link>
          </div>
        </div>

        {mentorshipPrograms.length > 0 && <div className={`section__navbar-dropdown-column section__navbar-dropdown-column--2`}>
            <div className="section__navbar-dropdown-column-header">
              <h4 className="section__navbar-dropdown-column-heading">
                Mentorship Programs
              </h4>
              <p className="section__navbar-dropdown-column-subheading">
                Get personalized guidance from industry experts
              </p>
            </div>
            <div className="section__navbar-dropdown-column-content">
              {mentorshipPrograms.length > 0 ? mentorshipPrograms.map((learner, index) => <Column2Link index={index} industryName={industries.find(industry => industry.id === selectedTabIndex)?.attributes.name || ""} learner={learner} program={mentorshipStandardLps.find(standardLP => Number(standardLP.attributes.productId) === learner.product_id) || mentorshipStandardLps[0]} setIsOpen={setIsOpen} key={index} />) : <div className="section__navbar-dropdown-link" style={{
            display: "flex",
            flexDirection: "column",
            gap: "4px"
          }}>
                  <h5 style={{
              fontSize: "14px",
              fontWeight: 500
            }}>
                    No programs available currently
                  </h5>
                  <p style={{
              fontSize: "12px",
              fontWeight: 400,
              opacity: 0.9
            }}>
                    Coming soon...
                  </p>
                </div>}
            </div>
          </div>}
        {selfPacedPrograms.length > 0 && <div className={`section__navbar-dropdown-column section__navbar-dropdown-column--3`}>
            <div className="section__navbar-dropdown-column-header">
              <h4 className="section__navbar-dropdown-column-heading">
                Self-Paced Programs
              </h4>
              <p className="section__navbar-dropdown-column-subheading">
                Programs to give you a perfect overview at your own speed
              </p>
            </div>
            <div className="section__navbar-dropdown-column-content">
              {selfPacedPrograms.length > 0 ? selfPacedPrograms.map((learner, index) => <Column3Link key={index} industryName={industries.find(industry => industry.id === selectedTabIndex)?.attributes.name || ""} learner={learner} program={selfPacedStandardLps.find(standardLP => Number(standardLP.attributes.productId) === learner.product_id) || selfPacedStandardLps[0]} setIsOpen={setIsOpen} />) : <div className="section__navbar-dropdown-link">
                  <h5>No programs available currently</h5>
                  <p>Coming soon...</p>
                </div>}
            </div>
          </div>}
      </div>
    </div>;
}
function Column2Link({
  industryName,
  learner,
  program,
  setIsOpen,
  index
}: {
  industryName: string;
  learner: Program;
  program: StandardLP;
  index: number;
  setIsOpen: (value: boolean) => void;
}) {
  const bgImages = [BG1, BG2, BG3];
  return <Link href={`/in/${sanitizeUrlSegment(program.attributes.name)}${routeVariables.PREMIUMCOURSE[0]}${program.attributes.productId}`} className="section__navbar-dropdown-link" onClick={() => {
    setItem("Industry", industryName);
    setItem("Program Name", program.attributes.name);
    setItem("Program Type", learner.programType);
    setIsOpen(false);
  }} data-sentry-element="Link" data-sentry-component="Column2Link" data-sentry-source-file="Navbar.tsx">
      <div className="section__navbar-dropdown-link-img">
        <Image src={program.attributes.banner_image.data?.attributes.url || ""} alt="GSLinkMegaMenu" width={100} height={100} className="section__navbar-dropdown-link-img-img" loading="lazy" data-sentry-element="Image" data-sentry-source-file="Navbar.tsx" />
        <Image src={bgImages[index % 3]} alt="GSLinkMegaMenu" width={100} className="section__navbar-dropdown-link-bg" loading="lazy" height={100} data-sentry-element="Image" data-sentry-source-file="Navbar.tsx" />
      </div>
      <div className="section__navbar-dropdown-link-text">
        <h5>{program.attributes.name}</h5>
        {program.attributes.premium_mentors.data.length > 0 && (program.attributes.premium_mentors.data.length > 1 ? <p>
              Multiple mentors from{" "}
              <span>
                {formatStringArray(program.attributes.premium_mentors.data.map(mentor => mentor.attributes.companyName))}
              </span>
            </p> : <p>
              by {program.attributes.premium_mentors.data[0]?.attributes?.name}
              {", "}
              <span>
                {program.attributes.premium_mentors.data[0]?.attributes?.designation && program.attributes.premium_mentors.data[0]?.attributes?.designation + ", "}
                {program.attributes.premium_mentors.data[0]?.attributes?.companyName}
              </span>
            </p>)}

        <div className="section__navbar-dropdown-link-stats">
          <div className="section__navbar-dropdown-link-stats-item">
            <div className="section__navbar-dropdown-link-stats-item-icon">
              <Image src={GSIconColumn} alt="GSIconColumn" width={15} height={15} loading="lazy" data-sentry-element="Image" data-sentry-source-file="Navbar.tsx" />
            </div>
            <p>{learner.duration}</p>
          </div>
          {learner.mappedCohort_StartDate && <div className="section__navbar-dropdown-link-stats-item">
              <div className="section__navbar-dropdown-link-stats-item-icon">
                <Image src={GSIconCalender} alt="GSIconCalender" width={15} height={15} />
              </div>
              <p>
                {new Date(learner.mappedCohort_StartDate) < new Date() ? "Coming Soon" : formatDateNormal(learner.mappedCohort_StartDate)}
              </p>
            </div>}
          {learner.sessions_type && <div className="section__navbar-dropdown-link-stats-item">
              <div className="section__navbar-dropdown-link-stats-item-icon">
                <Image src={GSIconLive} alt="GSIconLive" width={15} height={15} />
              </div>
              <p>{transformStrings(learner.sessions_type)}</p>
            </div>}
        </div>
      </div>
    </Link>;
}
function Column1Link({
  setIsColumnOpen,
  industryId,
  industryName,
  selectedTab,
  setSelectedTab
}: {
  setIsColumnOpen: React.Dispatch<React.SetStateAction<boolean>>;
  industryId: number;
  industryName: string;
  selectedTab: number;
  setSelectedTab: React.Dispatch<React.SetStateAction<number>>;
}) {
  return <button className={`section__navbar-dropdown-link ${selectedTab === industryId ? "active" : ""}`} onClick={() => {
    setIsColumnOpen(true);
    setSelectedTab(industryId);
  }} data-sentry-component="Column1Link" data-sentry-source-file="Navbar.tsx">
      <span>{industryName}</span>
      <div className="section__navbar-dropdown-link-img">
        <Image src={GSIconArrowRight} alt="GSIconArrowRight" width={24} height={24} loading="lazy" data-sentry-element="Image" data-sentry-source-file="Navbar.tsx" />
      </div>
    </button>;
}
function Column3Link({
  industryName,
  learner,
  program,
  setIsOpen
}: {
  industryName: string;
  learner: Program;
  program: StandardLP;
  setIsOpen: (value: boolean) => void;
}) {
  return <Link href={`/in/${sanitizeUrlSegment(program.attributes.name)}${routeVariables.PREMIUMCOURSE[1]}${program.attributes.productId}`} className="section__navbar-dropdown-link" onClick={() => {
    setItem("Industry", industryName);
    setItem("Program Name", program.attributes.name);
    setItem("Program Type", learner.programType);
    setIsOpen(false);
  }} data-sentry-element="Link" data-sentry-component="Column3Link" data-sentry-source-file="Navbar.tsx">
      <h5>{program.attributes.name}</h5>
      {program.attributes.premium_mentors.data && program.attributes.premium_mentors.data.length > 0 ? program.attributes.premium_mentors.data.length > 1 ? <p>
            Multiple mentors from{" "}
            <span>
              {formatStringArray(program.attributes.premium_mentors.data.map(mentor => mentor.attributes.companyName))}
            </span>
          </p> : <p>
            by {program.attributes.premium_mentors.data[0]?.attributes?.name}{" "}
            <span>
              {program.attributes.premium_mentors.data[0]?.attributes?.designation},{" "}
              {program.attributes.premium_mentors.data[0]?.attributes?.companyName}
            </span>
          </p> : <p>Mentor information not available</p>}
    </Link>;
}